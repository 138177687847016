<template>
    <div class="merchantEditSuccess">
        <template v-if="userStatus === 4 || userStatus === 7">
            <i class="el-icon-success icon"></i>
            <div class="name">提交成功</div>
            <div class="desc" style="margin-bottom: 18px">您的资料已提交，我们会在1-3个工作日内完成认证！</div>
            <el-button type="default" @click="$router.push('/admin/merchant/detail')">查看，企业入驻信息</el-button>
        </template>

        <template v-if="userStatus === 5">
            <i class="el-icon-error icon" style="color: #F56C6C"></i>
            <div class="name">申请驳回</div>
            <div class="desc" style="margin-bottom: 18px">{{infoData.auditOpinion}}</div>
            <el-button type="default" @click="$emit('changeStep', 0)">修改，企业入驻信息</el-button>
        </template>

        <template v-if="userStatus === 6">
            <i class="el-icon-success icon"></i>
            <div class="name">审核成功</div>
            <div class="desc"></div>
            <el-button type="default" @click="$emit('changeStep', 0)">修改，企业入驻信息</el-button>
        </template>
    </div>
</template>

<script>
export default {
  name: "adminMerchantEditFourth",
  props: {
    userStatus: {
      type: Number,
      default: 0
    },
    infoData: {
      type: Object,
      default: () => {return {}}
    }
  },
  data () {
    return {
      steps: 3,
      form: {
        goodsDetail: ''
      },
      isSubmitLoading: false,
      isRequestLoading: false,
    }
  },
  methods: {
  },
  components: {}
}
</script>
